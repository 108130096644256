@import '~ethos-design-system/src/components/Media/Media.scss';

@mixin MultiColumn__Row {
  display: flex;
  flex-wrap: wrap;

  @include for-phone-only {
    flex-direction: column;
    max-width: 327px;
    margin: 0 auto;
  }

  @include for-tablet-and-up {
    justify-content: space-between;
  }

  .MultiColumn__Column {
    flex-grow: 1;

    a {
      display: inline-block;

      &:hover {
        color: var(--GraySecondary--translucent);
        span {
          text-decoration: underline;
        }
      }

      &:active {
        color: var(--GraySecondary--translucent);
        span {
          text-decoration: none;
        }
      }
    }

    @include for-phone-only {
      flex-basis: 100%;

      &:not(:last-child) {
        padding-bottom: var(--Space-40);
      }
    }

    .MultiColumn__IconImage {
      width: 56px;
      height: 56px;

      img {
        height: 100%;
        object-fit: contain;
      }

      &.MultiColumn__IconImageCentered {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .MultiColumn__CenterTitle {
      text-align: center;
      max-width: 90%;
      margin: 0 auto;
    }

    .MultiColumn__BodyCopy {
      color: var(--GraySecondary--translucent);
      padding-top: var(--Space-8);

      @include for-phone-only {
        padding-top: var(--Space-4);
      }
    }

    .MultiColumn__ColumnCTA {
      padding-top: var(--Space-16);

      @include for-phone-only {
        padding-top: var(--Space-8);
      }
    }
  }
}

.MultiColumn__Wrapper {
  .MultiColumn__Headings {
    max-width: 560px;

    @include for-phone-only {
      max-width: 327px;
      margin: 0 auto;
    }

    .MultiColumn__Eyebrow {
      @include for-phone-only {
        padding-top: var(--Space-40);
      }
      @include for-tablet-and-laptop {
        padding-top: var(--Space-72);
      }
      @include for-desktop-only {
        padding-top: var(--Space-80);
      }
    }

    .MultiColumn__NoEyebrow {
      @include for-phone-only {
        height: var(--Space-32);
      }
      @include for-tablet-and-laptop {
        height: var(--Space-64);
      }
      @include for-desktop-only {
        height: var(--Space-72);
      }
    }

    .MultiColumn__Subhead {
      color: var(--GraySecondary--translucent);

      &.MultiColumn__SubheadLeftAlign {
        color: var(--GrayPrimary--opaque);
        max-width: 400px;
      }

      &.MultiColumn__SubheadAlternate {
        color: var(--GrayPrimary--opaque);
      }

      @include for-phone-only {
        padding-top: var(--Space-8);
      }
      @include for-tablet-and-up {
        padding-top: var(--Space-16);
      }
    }
  }

  .MultiColumn__HeadingsCentered {
    @extend .MultiColumn__Headings;

    text-align: left;

    @include for-phone-only {
      margin: inherit;
    }

    @include for-tablet-and-up {
      margin: auto;
      text-align: center;
    }

    .MultiColumn__SubheadCentered {
      @extend .MultiColumn__Subhead;

      @include for-tablet-and-up {
        max-width: 560px;
      }
    }
  }

  :global([class^='Button-']) {
    @include for-phone-only {
      width: 100%;
    }
  }

  .MultiColumn__MiddleCTA {
    padding-top: var(--Space-24);

    @include for-phone-only {
      max-width: 327px;
      margin: 0 auto;
      padding-bottom: var(--Space-40);
    }
    @include for-tablet-and-laptop {
      padding-bottom: var(--Space-56);
    }
    @include for-desktop-only {
      padding-bottom: var(--Space-64);
    }
  }

  .MultiColumn__NoMiddleCTA {
    @include for-phone-only {
      max-width: 327px;
      margin: 0 auto;
      height: var(--Space-64);
    }
    @include for-tablet-and-laptop {
      height: var(--Space-72);
    }
    @include for-desktop-only {
      height: var(--Space-80);
    }
  }

  .MultiColumn__RowTwoColumns {
    @include MultiColumn__Row;

    .MultiColumn__Column {
      @include for-tablet-and-laptop {
        flex-basis: 220px;
        min-width: 45.3%; // 471 / 1040

        // Design spec of max 96px spacing @ tablet/laptop
        // 96 / 1040
        &:nth-child(odd) {
          margin-right: 9.3%;
        }

        &:nth-child(n + 3) {
          padding-top: var(--Space-56);
        }
      }

      @include for-desktop-only {
        flex-basis: 480px;
        max-width: 43.8%; // 560 / 1280

        // Design spec of max 160px spacing @ desktop
        // 160 / 1296
        &:nth-child(odd) {
          margin-right: 12.4%;
        }

        &:nth-child(n + 3) {
          padding-top: var(--Space-64);
        }
      }
    }
  }

  .MultiColumn__RowThreeColumns {
    @include MultiColumn__Row;

    .MultiColumn__Column {
      @include for-tablet-and-laptop {
        flex-basis: 138px;

        // Design spec of max 88px spacing @ tablet/laptop
        // 88 / 1040
        &:nth-child(2) {
          margin-left: 8.5%;
          margin-right: 8.5%;
        }
      }

      @include for-desktop-only {
        flex-basis: 240px;

        // Design spec of max 160px spacing @ desktop
        // 160 / 1296
        &:nth-child(2) {
          margin-left: 12.4%;
          margin-right: 12.4%;
        }
      }
    }
  }

  .MultiColumn__RowFourColumns {
    @include MultiColumn__Row;

    .MultiColumn__Column {
      @include for-tablet-and-laptop {
        flex-basis: 220px;
        min-width: 45.3%; // 471 / 1040

        // Design spec of max 96px spacing @ tablet/laptop
        // 96 / 1040
        &:nth-child(odd) {
          margin-right: 9.3%;
        }

        &:nth-child(1),
        &:nth-child(2) {
          padding-bottom: var(--Space-56);
        }
      }

      @include for-desktop-only {
        flex-basis: 480px;
        max-width: 43.8%; // 560 / 1280

        // Design spec of max 160px spacing @ desktop
        // 160 / 1296
        &:nth-child(odd) {
          margin-right: 12.4%;
        }

        &:nth-child(1),
        &:nth-child(2) {
          padding-bottom: var(--Space-64);
        }
      }
    }
  }

  .MultiColumn__EndCTA {
    @include for-phone-only {
      max-width: 327px;
      margin: 0 auto;
      padding-top: var(--Space-40);
      padding-bottom: var(--Space-40);
    }
    @include for-tablet-and-laptop {
      padding-top: var(--Space-72);
      padding-bottom: var(--Space-72);
    }
    @include for-desktop-only {
      padding-top: var(--Space-80);
      padding-bottom: var(--Space-80);
    }
  }

  .MultiColumn__NoEndCTA {
    @include for-phone-only {
      max-width: 327px;
      margin: 0 auto;
      height: var(--Space-40);
    }
    @include for-tablet-and-laptop {
      height: var(--Space-72);
    }
    @include for-desktop-only {
      height: var(--Space-80);
    }
  }
}

.MultiColumn__ImageMedium img {
  margin: 0 auto;
  width: auto;
  margin-top: var(--Space-12);

  @include for-laptop-and-up {
    margin-top: 0;
  }
}
